import React, { useEffect, useState } from 'react';
import pako from 'pako';
import './ProfileLink.css';
import {Alert, Container, Typography, useTheme} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import { KJUR, KEYUTIL, hextob64u, b64tohex } from 'jsrsasign';
import {decompressData, getRoundedHours} from "./Utils";
import {Check, Verified} from "@mui/icons-material";
import {CopyField, DefaultCopyField} from '@eisberg-labs/mui-copy-field';
import Grid from "@mui/material/Grid";
import CertAppendix from "./CertAppendix";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCXR2X2RyXktZUtXyI497ZkY5d0
QT0gL+ZaNgrSGKwmVn78AbrB2IbgH9RuDVNUveB1awv1sNNjO509lcIMxLY9hat/
1i/h7XoM+CHIPXFXAwXkTzrrvsL8AvS0YqiqRpG4D8ogZzcrQ00ZQy1ovmLh4E0t
BRe6mGArpRITsAwZowIDAQAB
-----END PUBLIC KEY-----`;

const Upwork = ({profileData, subType, alias}) => {
  // const [profileData, setProfileData] = useState(profileData);
  const [eligable, setEligable] = useState(false);
  const [message, setMessage] = useState("");
  // let [searchParams, setSearchParams] = useSearchParams();
  let [type, setType] = useState("");
  const theme = useTheme();
  const navigate = useNavigate('/');

  useEffect(()=>{
      if (profileData?.type !== 'upwork') navigate('/');
      if(subType==="badges" || subType==="") {
          if (profileData?.badge === "Top Rated Plus") {
              setMessage(
                  <>
                      <Typography variant={"body1"}>In appreciation of becoming<br/><br/></Typography>
                      <Typography color={"primary"} sx={{typography: {xs: 'h4', lg: {fontSize: 80}}}}><b>Top 3%
                          freelancer</b></Typography>
                      <Typography variant={"h6"}>
                          and aquiring <b style={{color: theme.palette.primary.main}}>Top Rated Plus badge</b> on <b
                          style={{color: theme.palette.secondary.main}}>Upwork.com</b></Typography>
                  </>
              );
              setEligable(true);
          } else if (profileData?.badge === "Top Rated") {
              setMessage(
                  <>
                      <Typography variant={"body1"}>In appreciation of becoming<br/><br/></Typography>
                      <Typography color={"primary"} sx={{typography: {xs: 'h4', lg: {fontSize: 80}}}}><b>Top 10%
                          freelancer</b></Typography>
                      <Typography variant={"h6"}>
                          and aquiring <b style={{color: theme.palette.primary.main}}>Top Rated badge</b> on <b
                          style={{color: theme.palette.secondary.main}}>Upwork.com</b></Typography>
                  </>
              );
              setEligable(true);
          } else if (profileData?.badge === "Rising Talent") {
          setMessage(
              <>
                  <Typography variant={"body1"}>In appreciation of aquiring<br/><br/></Typography>
                  <Typography color={"primary"} sx={{typography: {xs: 'h4', lg: {fontSize: 80}}}}><b>Rising Talent
                      </b></Typography>
                  <Typography variant={"h6"}>
                      badge on <b
                      style={{color: theme.palette.secondary.main}}>Upwork.com</b></Typography>
              </>
          );
          setEligable(true);
      } else {
              setEligable(false);
          }
      } else if (subType==="totalHours"){
              if (typeof profileData?.totalHours === "number") {
                  setMessage(
                      <>
                          <Typography variant={"body1"}>In appreciation of achieving<br/><br/></Typography>
                          <Typography color={"primary"} sx={{typography: {xs: 'h4', lg: {fontSize: 80}}}}><b>{getRoundedHours(profileData?.totalHours)} hours</b></Typography>
                          <Typography variant={"h6"}>
                             billed on <b
                              style={{color: theme.palette.secondary.main}}>Upwork.com</b></Typography>
                      </>
                  );
                  setEligable(true);
              } else {
                  setEligable(false);
              }
      } else if (subType==="jss"){
        if (typeof profileData?.jss === "number") {
            setMessage(
                <>
                    <Typography variant={"body1"}>In appreciation of achieving<br/><br/></Typography>
                    <Typography color={"primary"} sx={{typography: {xs: 'h4', lg: {fontSize: 80}}}}><b>{Math.floor(profileData?.jss)}% job success</b></Typography>
                    <Typography variant={"h6"}>
                        on <b
                        style={{color: theme.palette.secondary.main}}>Upwork.com</b></Typography>
                </>
            );
            setEligable(true);
        } else {
            setEligable(false);
        }
    } else {
          setEligable(false);
      }
  },[])


  return (
    <Container>
      {eligable?
          <>
          <div id="wrap">
        <div border-radius="50% 200% 40% 80%" className="css-1ueqzyg"></div>
        <div border-radius="50% 200% 40% 80%" className="css-10gqqxt"></div>
              <img
                  className={"verified-icon-xs"}
                  style={{ maxWidth: '100px', filter:"hue-rotate(20deg)" }}
                  src="/verified.png"
                  alt="Verified Badge"
              />
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          The certificate is valid and has been securely confirmed as authentic.
        </Alert>
        <div id="profile" className="profile-card text-center">
          <img
            className={"verified-icon"}
            style={{ maxWidth: '150px', position: 'absolute', right: '50px', top: '-50px',filter:"hue-rotate(20deg)" }}
            src="/verified.png"
            alt="Verified Badge"
          />
          <Typography sx={{typography:{xs:'h4',lg:'h2'}}}>Certificate</Typography>
          <Typography variant={'h5'}>of achievement</Typography>
          <br/>
          <br/>
          <div className="stat">
          <span id="badge">{message}</span>
          </div>
          <br/>
          <Typography variant={"subtitle1"} gutterBottom>This certificate is proudly awarded to</Typography>
          <br/>
          {profileData && (
            <>
              <br/>
              <img  id="profileImage" src={profileData.image} alt="Profile Image" />
              <br />
              <Typography style={{marginTop:"20px",wordBreak:'break-word'}} sx={{typography:{xs:'h5',lg:'h4'}}} fontWeight={600} id="profileName">{profileData.name}</Typography>
              <h4 id="profileTitle" hidden>{profileData.title}</h4>
              <br />
              <br />
                <Typography variant={"subtitle2"} >Issued by</Typography>
              <div style={{display:'inline-flex', alignItems:'center'}}>
              <Verified sx={{display:'flex',mr:1}}  color={"secondary"}/>
              <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  color={"secondary"}
                  sx={{
                    mr: 2,
                    display:'flex',
                    fontWeight: 700,
                    textDecoration: 'none',
                  }}
              >
                profile <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  color={"primary"}
                  sx={{
                    mr: 2,
                    display:'flex',
                    // fontWeight: 700,
                    textDecoration: 'none',
                  }}
              >award</Typography>
              </Typography>
              </div>
                {profileData?.date && <Typography sx={{mt:1}} variant={"subtitle2"} color={'gray'} fontSize={'10px'} fontWeight={600} >on {new Date(profileData?.date).toLocaleDateString()}</Typography>}
            </>
          )}
        </div>
          </div>
              <CertAppendix alias={alias}/>
          </>
          :
            <Typography variant={'h1'} color={"primary"} style={{ fontSize: '5em' }}>Page not found 404</Typography>}
    </Container>
  );
};

export default Upwork;
