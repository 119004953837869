import React, {useEffect,useRef, useState} from 'react';
import pako from 'pako';
import './ProfileLink.css';
import {
    Alert,
    Badge,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid, Slide, Snackbar,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {decompressData, getShortFollowers} from "./Utils";
import {
    People,
    Twitter
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ReCAPTCHA from "react-google-recaptcha";


const EmailDialog = ({dialogData, setDialogData}) => {
    const theme = useTheme();
    const apiUrl="https://cmwyu8nszg.execute-api.us-east-1.amazonaws.com";
    const reCaptchaRef = useRef({});
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(true);


    const sendEmail = (dialogData)=>{
        const {email,type,subType,data} = dialogData;
        return fetch(apiUrl+`?token=${captcha}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                type,
                subType,
                data:encodeURIComponent(data)
            })
        })
            .then(res=>{
                if(!res.ok) throw new Error(res.statusMessage);
                setSuccess(true);
                setDialogData(null);
                setCaptcha(null);
                setError(null);
                return res.text();
            }).catch(e=>{
                setError('Failed to register email');
            })
    }
    return (
        <>
            <Snackbar
                open={success}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={10000}
                onClose={()=>{setSuccess(false)}}
                // TransitionComponent=<Slide direction="up" />
            >
                <Alert sx={{fontSize:15}} variant={"filled"} severity={"success"}>Certificate was successfully sent to your email</Alert>
            </Snackbar>
        <Dialog
            open={dialogData!==null}
            onClose={()=>setDialogData(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Where should we send it?"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>{setDialogData({...dialogData, email:e.target.value})}}
                />
                <ReCAPTCHA style={{display:'ruby-text'}} ref={reCaptchaRef}
                           sitekey="6LcQVycqAAAAAE9kaOhU_bqcEgoVWsdSUaWbd19s" onChange={setCaptcha}/>
                {error?<Alert severity="error">{error}</Alert>:""}
            </DialogContent>
            <DialogActions>
                <Button onClick={async ()=>{ await sendEmail(dialogData)}} autoFocus>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
            </>
);
};

export default EmailDialog;
