import pako from "pako/dist/pako.esm.mjs";

export const decompressData = async (compressedData) => {
    try {
        const compressedBytes = Uint8Array.from(atob(compressedData), c => c.charCodeAt(0));
        const decompressedBytes = pako.inflate(compressedBytes);
        const decompressedString = new TextDecoder().decode(decompressedBytes);

        const jsonData = JSON.parse(decompressedString);
        return jsonData;
    } catch (error) {
        console.error('Error decompressing data:', error);
        throw error;
    }
};

export function getShortFollowers(num) {

    function intlFormat(num) {
        return new Intl.NumberFormat().format(Math.floor(num));
    }

    if (num >= 1000000000) {
        return intlFormat(Math.floor(num / 1000000000) * 1000000000 / 1000000000) + 'B';
    }
    if (num >= 100000000) {
        return intlFormat(Math.floor(num / 100000000) * 100000000 / 1000000) + 'M';
    }
    if (num >= 10000000) {
        return intlFormat(Math.floor(num / 10000000) * 10000000 / 1000000) + 'M';
    }
    if (num >= 1000000) {
        return intlFormat(Math.floor(num / 1000000) * 1000000 / 1000000) + 'M';
    }
    if (num >= 100000) {
        return intlFormat(Math.floor(num / 100000) * 100000 / 1000) + 'K';
    }
    if (num >= 10000) {
        return intlFormat(Math.floor(num / 10000) * 10000 / 1000) + 'K';
    }
    if (num >= 1000) {
        return intlFormat(Math.floor(num / 1000) * 1000 / 1000) + 'K';
    }
    if (num >= 100) {
        return intlFormat(Math.floor(num / 100) * 100);
    }
    if (num >= 10) {
        return intlFormat(Math.floor(num / 10) * 10);
    }
    return intlFormat(num);
}

export function getRoundedHours(num) {

    function roundToNearest(value, nearest) {
        return Math.floor(value / nearest) * nearest;
    }

    if (num >= 1000000000) {
        return roundToNearest(num, 1000000000).toString();
    }
    if (num >= 1000000) {
        return roundToNearest(num, 1000000).toString();
    }
    if (num >= 1000) {
        return roundToNearest(num, 1000).toString();
    }
    if (num >= 100) {
        return roundToNearest(num, 100).toString();
    }
    if (num >= 10) {
        return roundToNearest(num, 10).toString();
    }
    return num.toString();
}


