import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes, useSearchParams,
} from "react-router-dom";
import ProfileLink from "./ProfileLink";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import Search from "./Search";
import {RedirectUrl} from "./RedirectUrl";
import Footer from "./Footer";
import {useEffect} from "react";
import How from "./How";

const theme = createTheme({
    palette: {
        primary: {
            light: '#b1b5f1',
            main: '#7b55de',
            dark: 'rgb(76,57,155)',
            contrastText: '#fff',
        },
        secondary: {
            light: '#c0fff0',
            main: '#00a37e',
            dark: '#004939',
            contrastText: '#626262',
        },
    },
});


if (typeof atob === 'undefined') {
    global.atob = (input) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let str = input.replace(/=+$/, '');
        let output = '';

        for (
            let bc = 0, bs, buffer, idx = 0;
            (buffer = str.charAt(idx++));
            ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
            bc++ % 4) ?
                output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
        ) {
            buffer = chars.indexOf(buffer);
        }

        return output;
    };
}

function App() {
    return (
      <ThemeProvider theme={theme}>
      <Router>
          <ResponsiveAppBar/>
          <Routes>
            <Route exact path="/link" element={<ProfileLink/>} />
              <Route exact path="/how" element={<How/>} />
              <Route path="/share/:id" element={<RedirectUrl url="https://spoo.me/" />} />

              <Route exact path="/" element={<Search/>} />

              {/*<Navigate to="/" />*/}
          </Routes>
          <Footer/>
      </Router>
      </ThemeProvider>
  );
}

export default App;
