import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Container, Typography} from "@mui/material";

export const RedirectUrl = ({ url }) => {
    let {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(id.length===8) {
            window.location.href = url + id;
        }else{
           navigate('/');
        }
    }, [url]);

    return <Container sx={{m: {xs:2,lg:20}, textAlign:'center'}}><Typography sx={{typography: {xs: 'h4',lg:'h1'}}} variant={"h1"} color={'primary'}>Redirecting...</Typography></Container>;
};