import React, {useEffect,useRef, useState} from 'react';
import pako from 'pako';
import './ProfileLink.css';
import {
    Alert,
    Badge,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {decompressData, getShortFollowers} from "./Utils";
import {
    People,
    Twitter
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ReCAPTCHA from "react-google-recaptcha";
import EmailDialog from "./EmailDialog";


const UpworkCerts = ({scannedData, keyString}) => {
    const theme = useTheme();
    const [dialogData, setDialogData] = React.useState(null);
    return (
        <>
            <EmailDialog dialogData={dialogData} setDialogData={setDialogData}/>
            <div id="results" style={{
                width: "100%",
                backgroundColor: theme.palette.secondary.main,
                padding: "70px 0px",
                borderRadius: "25px",
                margin: "20px 0px 60px 0px"
            }}>
                <Typography style={{marginBottom: "20px"}} variant={"h4"} component={"p"} fontWeight={200} id="profileName"
                            color={"primary"}>Hello <b style={{color: "white"}}>{scannedData?.name}</b>!</Typography>
                <img id="profileImage" style={{maxWidth: 100, maxHeight: 100, border: '5px solid white'}}
                     src={scannedData?.image} alt="Profile Image"/>
                <br/>
                <Typography style={{marginTop: "20px"}} variant={"h6"} fontWeight={200} id="profileName"
                            color={"white"}>You are <b>eligible</b> for these certificates</Typography>
            </div>
            <Box sx={{flexGrow: 1, width: "100%"}}>
                <Grid container alignItems={"center"} spacing={3}>
                    <Grid item lg={4} xs={12}>

                        <Card variant={"outlined"} className={'certs-card'}>
                            <Badge badgeContent={"FEATURED"} color="primary">
                                <People className={'certs-icon'} color={"secondary"}/>
                            </Badge>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {getShortFollowers(scannedData?.followers)} followers
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    X(Twitter) number of followers milestone
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"
                                        variant={"contained"}
                                    onClick={()=>setDialogData({type:"twitter",subType:"followers", data: keyString})}
                                >
                                    Get certificate
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    {/*<Grid item lg={4} xs={12}>*/}
                    {/*    <Card variant={"outlined"} sx={{padding: "20px"}}>*/}
                    {/*        <ThumbUp className={'certs-icon'} color={"secondary"}/>*/}
                    {/*        <CardContent>*/}
                    {/*            <Typography gutterBottom variant="h5" component="div">*/}
                    {/*                {getShortFollowers(scannedData?.likes)} likes*/}
                    {/*            </Typography>*/}
                    {/*            <Typography variant="body2" color="text.secondary">*/}
                    {/*                X(Twitter) number of likes milestone*/}
                    {/*            </Typography>*/}
                    {/*        </CardContent>*/}
                    {/*        <CardActions>*/}
                    {/*            <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"*/}
                    {/*                    variant={"contained"}*/}
                    {/*                    href={`/link?type=twitter&id=${encodeURIComponent(keyString)}`}>*/}
                    {/*                Get certificate*/}
                    {/*            </Button>*/}
                    {/*        </CardActions>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    <Grid item lg={4} xs={12}>
                        <Card variant={"outlined"} className={'certs-card'}>
                            <Twitter className={'certs-icon'} color={"secondary"}/>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {getShortFollowers(scannedData?.tweets)} tweets
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    X(Twitter) number of tweets milestone
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"
                                        variant={"contained"}
                                        onClick={()=>setDialogData({type:"twitter",subType:"tweets", data: keyString})}>
                                    Get certificate
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UpworkCerts;
