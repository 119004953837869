import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileLink.css';
import ConfettiExplosion from 'react-confetti-explosion';
import {
    Alert, Card, CardActionArea, CardActions, CardContent, CardMedia,
    CircularProgress, Collapse,
    Container, Dialog, DialogContent, DialogTitle, Grid,
    InputAdornment,
    Link,
    Select, Snackbar,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {ReactTyped} from "react-typed";
import {
    MilitaryTechOutlined,
    PercentOutlined, SearchOutlined, VerifiedOutlined,
    WorkOutline,
    WorkOutlined,
    WorkOutlineOutlined
} from "@mui/icons-material";
import {decompressData} from "./Utils";
import Box from "@mui/material/Box";
import UpworkCerts from "./UpworkCerts";
import TwitterCerts from "./TwitterCerts";
import ReCAPTCHA from 'react-google-recaptcha';
import UpworkHelpDialog from "./UpworkHelpDialog";
import Features from "./Features";
import FAQ from "./FAQ";


const Search = () => {
    let navigate = useNavigate();
    const theme = useTheme();
    const [id,setId] = useState("");
    const [platform,setPlatform] = useState("upwork");
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const {primary, secondary} = theme.palette.text;
    const [scannedData, setScannedData] = useState(null);
    const [key, setKey] = useState("");
    const [isExploding, setIsExploding] = useState(false);
    const reCaptchaRef = useRef({});
    const [upworkHelpDialog, setUpworkHelpDialog] = useState(false);
    const [captcha, setCaptcha] = useState(null);

    const fetchData=async ()=>{
        // await reCaptchaRef.current.reset();
        // const token = await reCaptchaRef.current.execute();
        const token = captcha;
        const apiUrl = `https://cmwyu8nszg.execute-api.us-east-1.amazonaws.com?type=${platform.toLowerCase()}&id=${id}&token=${token}`;
        setLoading(true);
        setError(null);
        setScannedData(null);
        setKey(null);
        fetch(apiUrl)
            .then(res=>{
                if(!res.ok) throw new Error(res.statusMessage)
                return res.text();
            })
            .then(async (data)=>{
                // navigate(`/link?id=${encodeURIComponent(data.toString())}`)
                setKey(data.toString());
                setScannedData(await decompressData(data.toString().split(".")[0]));
                setLoading(false);
                setIsExploding(true);
            }).catch(e=>{
                if(platform==='upwork') {
                    fetch(apiUrl)
                        .then(res => {
                            if (!res.ok) throw new Error(res.statusMessage)
                            return res.text();
                        })
                        .then(async (data) => {
                            // navigate(`/link?id=${encodeURIComponent(data.toString())}`)
                            setKey(data.toString());
                            setScannedData(await decompressData(data.toString().split(".")[0]));
                            setLoading(false);
                        }).catch(e => {
                        setLoading(false);
                        setError("Failed to scan profile");
                    });
                }else{
                    setLoading(false);
                    setError("Failed to scan profile");
                }
                // setLoading(false);
                // setError("Failed to scan");
            });
    };
  useEffect(() => {

  }, []);

  function getCerts(platform){
    switch (platform){
        case 'upwork':
            return <UpworkCerts scannedData={scannedData} keyString={key}/>;
        case 'twitter':
            return <TwitterCerts scannedData={scannedData} keyString={key}/>;
        default:
            return <></>;
    }
  }

    function getPlaceholder(platform){
        switch (platform){
            case 'upwork':
                return "~017f5baf48dd29728b";
            case 'twitter':
                return "elonmusk";
            default:
                return "";
        }
    }

    function isScanDisabled(id, platform){
        switch (platform){
            case 'upwork':
                return id==null || id==="" || !id?.startsWith("~");
            case 'twitter':
                return id==null || id==='';
            default:
                return false;
        }
    }

  return (
    <Container maxWidth={"xl"}>
        <UpworkHelpDialog open={upworkHelpDialog} setOpen={setUpworkHelpDialog}/>
      <div id="wrap2">
        <div border-radius="50% 200% 40% 80%" className="css-1ueqzyg"></div>
        <div border-radius="50% 200% 40% 80%" className="second_glare"></div>

        {/*<div id="profile" className="text-center">*/}
            <Grid id={"scan"} container spacing={1}>
                <Grid item lg={7} xs={12} textAlign={"center"}>
                    <div style={{marginLeft:"0px",width:"100%"}}>
                    <Typography style={{margin:"70px 0px 20px 0px"}} color={"primary"} sx={{typography:{xs:'h2',lg:'h1'}}}>Certify profile <br/><ReactTyped
                        strings={["milestone","followers","views","badges"]}
                        startDelay={1000}
                        typeSpeed={100}
                        loop
                        backSpeed={20}
                        cursorChar="|"
                        showCursor={true}
                        style={{color:theme.palette.secondary.main}}
                    /></Typography>
                    <Typography variant={"h5"}>sharable.printable.<span style={{color:theme.palette.secondary.main}}>free.</span></Typography>
                    </div>

            {loading?<CircularProgress />:
                <div style={{margin:"70px 0px"}}>
                    <TextField style={{paddingBottom:"20px"}} onChange={event => setId(event.target.value)}
                               placeholder={getPlaceholder(platform)}
                               InputProps={{
                                   // endAdornment:
                                   //      (
                                   //      <Button style={{padding:"15px 45px"}} variant={"contained"} color={"primary"} onClick={fetchData}disabled={id!=null && !id?.includes("~")}>Certify</Button>
                                   //      ),
                                   style:{padding:"0px"},
                                   startAdornment: (<Select sx={{color:'primary'}} SelectDisplayProps={{style:{padding:"15px 30px"}}}
                                                            color={"primary"}
                                                            variant={"outlined"}
                                                            value={platform}
                                                            onChange={e=>{setScannedData(null);setPlatform(e.target.value);}}>
                                            <MenuItem value={"upwork"}>Upwork</MenuItem>
                                            <MenuItem value={"twitter"}>X(twitter)</MenuItem>
                                        </Select>
                                   )}}
                               inputProps={{style:{padding:"0px 20px"}}}
                   />

                    <Button size={'small'} style={{padding:"15px 25px"}} variant={"contained"} color={"primary"} onClick={fetchData } disabled={isScanDisabled(id,platform) || captcha==null}>Scan my profile{isExploding && <ConfettiExplosion duration={4000} particleCount={250} width={1600}/>}
                    </Button>
                    {!isScanDisabled(id,platform) && <ReCAPTCHA style={{display:'ruby-text'}} /*style={{visibility:"hidden",position:'absolute !important'}} size={"invisible"}*/ ref={reCaptchaRef}
                                                                sitekey="6LcQVycqAAAAAE9kaOhU_bqcEgoVWsdSUaWbd19s" onChange={setCaptcha}/>}
                    {platform==='upwork' && <><Typography style={{marginTop:"20px",cursor:"pointer"}} fontWeight={600} variant={"subtitle2"} color={"secondary"}><Link onClick={()=>setUpworkHelpDialog(true)}>How to get my Upwork profile id?</Link></Typography>
                        <Typography fontWeight={600} variant={"subtitle2"} color={"secondary"}>Only PUBLIC profiles!</Typography></>}
                    {error?<Alert severity="error">{error}</Alert>:""}
                </div>}

                </Grid>
                <Grid item lg={5} xs={12}>
                    <img
                        style={{
                            // float:"left",
                            margin:"0px",
                            maxWidth: '550px',
                            width:'100%',
                            position: "relative",
                            top: "50%",
                            transform: "translateY(-50%)",
                            boxShadow: '0 24px 48px 0 rgba(76, 57, 155, 0.6), 0 24px 48px 0 rgba(76, 57, 155, 0.8)'
                            // border: '30px solid #4c399b',  boxShadow: "rgb(76 57 155 / 38%) 20px 20px 10px"

                            // transform:'perspective(400px) rotate3D(0, -1, 0, 15deg)'
                    }}
                        src="/thumbnailprofilechecker.png"
                        alt="Verified Badge"
                    />
                </Grid>
            </Grid>
        {/*</div>*/}

      </div>
        <Collapse style={{textAlign:"center"}} in={scannedData===null} >
        <div id={"how"} style={{width:"100%",
            boxShadow: '0 25px 35px rgba(0, 0, 0, 0.2)',
            backgroundColor:theme.palette.secondary.main,
            padding:"70px 0px",borderRadius:"25px",margin:"20px 0px 60px 0px",
        }}>
            <Grid container spacing={3}>
                <Grid item lg={4} xs={12}>
                    <Card sx={{padding: "0px",backgroundColor:"transparent",border:"none",boxShadow:"none",color:theme.palette.primary.contrastText}}>
                        <SearchOutlined style={{fontSize: "4em",}} />
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                Scan profile
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div">
                                No signup needed
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <Card sx={{padding: "0px",backgroundColor:"transparent",border:"none",boxShadow:"none",color:theme.palette.primary.contrastText}}>
                        <VerifiedOutlined style={{fontSize: "4em",}} />
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                Choose achievement
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div">
                                followers, views, likes, badges
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <Card sx={{padding: "0px",backgroundColor:"transparent",border:"none",boxShadow:"none",color:theme.palette.primary.contrastText}}>
                        <MilitaryTechOutlined style={{fontSize: "4em",}} />
                         <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                Get certificate
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div">
                                permanent sharable link
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
        </Collapse>
        <Collapse style={{textAlign:"center"}} in={scannedData} >
            {getCerts(platform)}
        </Collapse>
        <Features/>
        <FAQ/>
    </Container>
  );
};

export default Search;
