import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from "react";
import {Typography, useTheme} from "@mui/material";

export default function UpworkHelpDialog( {open,setOpen}) {
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open===true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize:30, color:theme.palette.secondary.main}}>
          {"How to get my Upwork profile id?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Every upwork profile has a unique id that looks like: <br/><b style={{color:theme.palette.primary.main, fontSize:20}}>~017f5baf48dd29728b</b>.
            <br/>
            <br/>
            You can find it by visiting profile in the browser and looking into the URL:
            <br/>
            <Typography sx={{fontSize:{xs:15,md:20}}}><b>upwork.com/freelancers/<span style={{color:theme.palette.primary.main}}>~017f5b...</span></b></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*<Button sx={{padding:5}} onClick={handleClose} autoFocus>*/}
          {/*  Got it*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}