import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Verified} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";

const pages = [
    {
        label:'Scan my profile',
        path: '/#scan'
    },
    {
        label:'How it works?',
        path: '/how'
    },
    {
        label:'Support us',
        path: 'https://spoo.me/padonate'
    }
    ];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (page) => {
        if(page.path.startsWith("http")){
            window.location.href=page.path;
        }else {
            navigate(page.path);
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static"
                color={"default"}
                // color={"transparent"}
            style={{
                border:'none',
                boxShadow:'none'
            }}
                sx={{
                    bgcolor:"white",
                    }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Verified sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}  color={"secondary"} />
                    <Link href={'/'} sx={{textDecoration:'none'}}>
                    <Typography
                        variant="h5"
                        noWrap
                        component="h1"
                        href="/"
                        color={"secondary"}
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontWeight: 700,
                            textDecoration: 'none',
                        }}
                    >
                        profile <Typography
                        variant="h5"
                        noWrap
                        component="h1"
                        href="/"
                        color={"primary"}
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            // fontWeight: 700,
                            textDecoration: 'none',
                        }}
                    >award
                    </Typography>
                    </Typography>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="gray"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.label} onClick={()=>handleCloseNavMenu(page)}>
                                    <Typography textAlign="center" color={"primary"} >{page.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Verified sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} color={"secondary"} />
                    <Link href={'/'} sx={{textDecoration:'none',  display: { xs: 'flex', md: 'none' }, flexGrow: 1,}}>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        color={"secondary"}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            textDecoration: 'none',
                            fontWeight: 700,
                        }}
                    >
                        profile<Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        color={"primary"}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            textDecoration: 'none',
                        }}
                    >award</Typography>
                    </Typography>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },marginLeft:'150px' }}>
                        {pages.map((page) => (
                            <Button
                                key={page.label}
                                variant={'text'}
                                onClick={()=>handleCloseNavMenu(page)}
                                sx={{ my: 2, mr:3, color: 'primary', display: 'block',  textTransform: 'none'}}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>

                    {/*<Box sx={{ flexGrow: 0 }}>*/}
                    {/*    <Tooltip title="Open settings">*/}
                    {/*        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
                    {/*            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*    <Menu*/}
                    {/*        sx={{ mt: '45px' }}*/}
                    {/*        id="menu-appbar"*/}
                    {/*        anchorEl={anchorElUser}*/}
                    {/*        anchorOrigin={{*/}
                    {/*            vertical: 'top',*/}
                    {/*            horizontal: 'right',*/}
                    {/*        }}*/}
                    {/*        keepMounted*/}
                    {/*        transformOrigin={{*/}
                    {/*            vertical: 'top',*/}
                    {/*            horizontal: 'right',*/}
                    {/*        }}*/}
                    {/*        open={Boolean(anchorElUser)}*/}
                    {/*        onClose={handleCloseUserMenu}*/}
                    {/*    >*/}
                    {/*        {settings.map((setting) => (*/}
                    {/*            <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
                    {/*                <Typography textAlign="center">{setting}</Typography>*/}
                    {/*            </MenuItem>*/}
                    {/*        ))}*/}
                    {/*    </Menu>*/}
                    {/*</Box>*/}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
