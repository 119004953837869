import React, { useEffect, useState } from 'react';
import pako from 'pako';
import './ProfileLink.css';
import {Alert, Container, Typography, useTheme} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { KJUR, KEYUTIL, hextob64u, b64tohex } from 'jsrsasign';
import {decompressData} from "./Utils";
import {Check, Verified} from "@mui/icons-material";
import Upwork from "./Upwork";
import Twitter from "./Twitter";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCXR2X2RyXktZUtXyI497ZkY5d0
QT0gL+ZaNgrSGKwmVn78AbrB2IbgH9RuDVNUveB1awv1sNNjO509lcIMxLY9hat/
1i/h7XoM+CHIPXFXAwXkTzrrvsL8AvS0YqiqRpG4D8ogZzcrQ00ZQy1ovmLh4E0t
BRe6mGArpRITsAwZowIDAQAB
-----END PUBLIC KEY-----`;

const ProfileLink = () => {
  const [initialized, setInitialized] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [eligable, setEligable] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let [type, setType] = useState("");
  let [subType, setSubType] = useState("");
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      // const urlParams = new URLSearchParams(window.location.search);
      // console.log(urlParams);
      const signedString = searchParams.get("id");
      if(!signedString) return;
      const queryType = searchParams?.get("type");
      if (queryType) setType(queryType);
      const querySubType = searchParams?.get("subType");
      if (querySubType) setSubType(querySubType);
      const [compressedData, signature] = signedString.split('.');

      const isValid = await verifySignature(compressedData, signature, publicKey);
      if (isValid) {
        console.log('Signature is valid!');

        const decompressedData = await decompressData(compressedData);
        setProfileData(decompressedData);
      } else {
        setProfileData(null);
        console.error('Signature is not valid!');
      }
      setInitialized(true);
    };

    fetchData();
  }, []);


  const verifySignature = (data, signature, publicKeyPEM) => {
    try {
      // Convert the PEM public key to a format that jsrsasign can use
      const publicKey = KEYUTIL.getKey(publicKeyPEM);

      // Create a new Signature object with the SHA256withRSA algorithm
      const sig = new KJUR.crypto.Signature({ alg: 'SHA256withRSA' });

      // Initialize the Signature object with the public key
      sig.init(publicKey);

      // Update the Signature object with the data to be verified
      sig.updateString(data);

      // Convert the signature from Base64 to hexadecimal
      const signatureHex = b64tohex(signature);

      // Verify the signature
      const isValid = sig.verify(signatureHex);

      return isValid;
    } catch (error) {
      console.error('Error verifying signature:', error);
      return false;
    }
  };

  function render404(){
    return (<Container sx={{m:20, textAlign:'center'}}>
      <Typography variant={'h1'} color={"primary"} sx={{mt:20,mb:20}} style={{ fontSize: '5em' }}>Page not found 404</Typography>
    </Container>)
  }

  function renderComponent(type){

    if(!initialized){
      return (<Container sx={{m:{xs:2,lg:20}, textAlign:'center'}}>
        <Typography color={"primary"} sx={{mt:{xs:20,lg:20},mb:{xs:20,lg:20},typography: {xs: 'h4',lg:'h1'}}}  style={{ fontSize: '5em' }}>Loading...</Typography>
      </Container>)
    }

    if(profileData === null){
      render404();
    }

    if(profileData?.type!==type){
      render404();
    }

    switch (type) {
      case 'upwork':
        return (<Upwork profileData={profileData} subType={subType} alias={profileData?.alias}/>);
      case 'twitter':
        return (<Twitter profileData={profileData} subType={subType} alias={profileData?.alias}/>);
      default:
        return render404();
    }
  }

  return (
      <>
        {renderComponent(type)}
      </>
  );
};

export default ProfileLink;
