import React, {useEffect, useState} from 'react';
import pako from 'pako';
import './ProfileLink.css';
import {Alert, Badge, Card, CardActions, CardContent, Container, Grid, Typography, useTheme} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {KJUR, KEYUTIL, hextob64u, b64tohex} from 'jsrsasign';
import {decompressData, getRoundedHours, getShortFollowers} from "./Utils";
import {Check, MilitaryTechOutlined, PercentOutlined, Verified, WorkOutlineOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EmailDialog from "./EmailDialog";


const UpworkCerts = ({scannedData,keyString}) => {
    const theme = useTheme();
    const [dialogData, setDialogData] = React.useState(null);

    function getSubtitle(badge){
        switch (badge){
            case "Top Rated":
                return  "Ranked top 10% of all Upwork talent";
            case "Top Rated Plus":
                return  "Ranked top 3% of all Upwork talent";
            case "Rising Talent":
                return  "Demonstrated great potential on Upwork";
        }
    }

    return (
        <>
            <EmailDialog setDialogData={setDialogData} dialogData={dialogData}/>
            <div id="results" style={{
                width: "100%",
                backgroundColor: theme.palette.secondary.main,
                padding: "70px 0px",
                borderRadius: "25px",
                margin: "20px 0px 60px 0px"
            }}>
                <Typography style={{marginBottom: "20px"}} variant={"h4"} component={"p"} fontWeight={200} id="profileName"
                            color={"primary"}>Hello <b style={{color: "white"}}>{scannedData?.name}</b>!</Typography>
                <img id="profileImage" style={{maxWidth: 100, maxHeight: 100, border: '5px solid white'}}
                     src={scannedData?.image} alt="Profile Image"/>
                <br/>
                <Typography style={{marginTop: "20px"}} variant={"h6"} fontWeight={200} id="profileName"
                            color={"white"}>You are <b>eligible</b> for these certificates</Typography>
            </div>
            <Box sx={{flexGrow: 1, width: "100%"}}>
                <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                        {["Top Rated", "Top Rated Plus", "Rising Talent"].includes(scannedData?.badge) ?
                            <Card variant={"outlined"} className={'certs-card'}>
                                <Badge badgeContent={"FEATURED"} color="primary">
                                    <MilitaryTechOutlined className={"certs-icon"} color={"secondary"}/>
                                </Badge>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {scannedData?.badge} badge
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {getSubtitle(scannedData?.badge)}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"
                                            variant={"contained"}
                                            onClick={()=>setDialogData({type:"upwork",subType:"badges", data: keyString})}>
                                        Get certificate
                                    </Button>
                                </CardActions>
                            </Card> :
                            <Card variant={"outlined"} className={'certs-card'}>
                                <MilitaryTechOutlined className={"certs-icon"} color={"disabled"}/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Upwork badge
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Not eligable
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button disabled style={{width: "100%", padding: "10px"}} size="small"
                                            color="primary"
                                            variant={"contained"}>
                                        Get certificate
                                    </Button>
                                </CardActions>
                            </Card>}
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        { typeof scannedData?.jss === 'number' ?
                            <Card variant={"outlined"} className={'certs-card'}>
                                    <PercentOutlined className={"certs-icon"} color={"secondary"}/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {Math.floor(scannedData?.jss)}% Job success
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Job success score on Upwork
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"
                                            variant={"contained"}
                                            onClick={()=>setDialogData({type:"upwork",subType:"jss", data: keyString})}>
                                        Get certificate
                                    </Button>
                                </CardActions>
                            </Card> :
                            <Card variant={"outlined"} className={'certs-card'}>
                                <PercentOutlined className={"certs-icon"} color={"disabled"}/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Job success score
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Not eligable
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button disabled style={{width: "100%", padding: "10px"}} size="small"
                                            color="primary"
                                            variant={"contained"}>
                                        Get certificate
                                    </Button>
                                </CardActions>
                            </Card>}
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        { typeof scannedData?.totalHours === 'number' ?
                            <Card variant={"outlined"} className={'certs-card'}>
                                <WorkOutlineOutlined className={"certs-icon"} color={"secondary"}/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {getRoundedHours(scannedData?.totalHours)} total hours
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Total hours billed on Upwork hourly contracts
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"
                                            variant={"contained"}
                                            onClick={()=>setDialogData({type:"upwork",subType:"totalHours", data: keyString})}>
                                        Get certificate
                                    </Button>
                                </CardActions>
                            </Card> :
                            <Card variant={"outlined"} className={'certs-card'}>
                                <WorkOutlineOutlined className={"certs-icon"} color={"disabled"}/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Total hours
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Total hours billed on Upwork hourly contracts
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button disabled style={{width: "100%", padding: "10px"}} size="small"
                                            color="primary"
                                            variant={"contained"}>
                                        Get certificate
                                    </Button>
                                </CardActions>
                            </Card>}
                    </Grid>
                    {/*<Grid item lg={4} xs={12}>*/}
                    {/*    <Card variant={"outlined"} className={'certs-card'}>*/}
                    {/*        <PercentOutlined className={"certs-icon"} color={"secondary"}/>*/}
                    {/*        <CardContent>*/}
                    {/*            <Typography gutterBottom variant="h5" component="div">*/}
                    {/*                Job success score*/}
                    {/*            </Typography>*/}
                    {/*            <Typography variant="body2" color="text.secondary">*/}
                    {/*                Job success percentage on Upwork*/}
                    {/*            </Typography>*/}
                    {/*        </CardContent>*/}
                    {/*        <CardActions>*/}
                    {/*            <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"*/}
                    {/*                    variant={"outlined"}>*/}
                    {/*                Coming soon*/}
                    {/*            </Button>*/}
                    {/*        </CardActions>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                {/*    <Grid item lg={4} xs={12}>*/}
                {/*        <Card variant={"outlined"} className={'certs-card'}>*/}
                {/*            <WorkOutlineOutlined className={'certs-icon'} color={"secondary"}/>*/}
                {/*            <CardContent>*/}
                {/*                <Typography gutterBottom variant="h5" component="div">*/}
                {/*                    Total hours*/}
                {/*                </Typography>*/}
                {/*                <Typography variant="body2" color="text.secondary">*/}
                {/*                    Total hours billed on Upwork hourly contract*/}
                {/*                </Typography>*/}
                {/*            </CardContent>*/}
                {/*            <CardActions>*/}
                {/*                <Button style={{width: "100%", padding: "10px"}} size="small" color="primary"*/}
                {/*                        variant={"outlined"}>*/}
                {/*                    Coming soon*/}
                {/*                </Button>*/}
                {/*            </CardActions>*/}
                {/*        </Card>*/}
                {/*    </Grid>*/}
                </Grid>
            </Box>
        </>
    );
};

export default UpworkCerts;
