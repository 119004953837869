import React, {useEffect,useRef, useState} from 'react';
import pako from 'pako';
import './ProfileLink.css';
import {
    Alert,
    Badge,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {DefaultCopyField} from "@eisberg-labs/mui-copy-field";
import QRCode from "react-qr-code";



const CertAppendix = ({alias}) => {
    const theme = useTheme();
    const apiUrl="https://cmwyu8nszg.execute-api.us-east-1.amazonaws.com";
    return (
        <Grid sx={{mt:3, mb:10}} container spacing={3} alignItems={'center'} justifyContent={"center"}>
            <Grid item xs={12} md={4}>
                {alias &&
                    <>
                        <div style={{ height: "auto", margin: "25px auto", maxWidth: 100, width: "100%" }}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={`https:profileaward.com/share/${alias}`}
                                viewBox={`0 0 256 256`}
                                fgColor={theme.palette.primary.dark}
                            />
                        </div>
                    <DefaultCopyField sx={{width:'100%', boxShadow:'#a798e5 0px 24px 48px 0px'}}
                                      variant={'outlined'}
                                      inputProps={{sx:{
                                              fontWeight:700,
                                              color:theme.palette.primary.main
                                          }}}
                                      fullWidth
                                      color={"primary"}
                                      label="Short URL"
                                      value={`https://profileaward.com/share/${alias}`}
                    />
                    </>}
            </Grid>
        </Grid>
);
};

export default CertAppendix;
