import React from 'react';
import './ProfileLink.css';
import {Container, Typography, useTheme} from "@mui/material";
import Upwork from "./Upwork";
import Twitter from "./Twitter";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";


const How = () => {
  const theme = useTheme();

  return (
      <Container sx={{mb:10}}>
        <Typography variant={"h2"} color={"secondary"} sx={{textAlign:"center", mt:5}} gutterBottom>How it works?</Typography>
        <Typography variant={"h4"} color={"primary"} gutterBottom  sx={{mt:5}}>1. Scan your social media profile</Typography>
        <Typography variant={"body1"} color={"text.secondary"} gutterBottom>
          Our system is going to scan your profile and check for achievements.
          For restricted platforms(e.g. Upwork) your profile has to be publicly available. You can make it public temporarily just to scan it.
          The system is going to look for the profile and verify milestone information.
        </Typography>
        <Typography variant={"h4"} color={"primary"} gutterBottom  sx={{mt:5}}>2. Get eligible profile achievements </Typography>
        <Typography variant={"body1"} color={"text.secondary"}>
          You'll get a list of achievements and milestones that are available for your profile. Depending on the platform, milestones can include
          number of followers, likes, views, subscribers, badges etc. For each milestone you will be offered a digital certificate.
        </Typography>
        <Typography variant={"h4"} color={"primary"} gutterBottom  sx={{mt:5}}>3. Choose milestone certificate </Typography>
        <Typography variant={"body1"} color={"text.secondary"}>
          You can choose for which milestone you want the certificate to be issued for. Certificate is going to be instantly generated and sent to your
          email.
        </Typography>
        <Typography variant={"h4"} color={"primary"} gutterBottom  sx={{mt:5}}>4. Verify, share, print! </Typography>
        <Typography variant={"body1"} color={"text.secondary"}>
          The unique link sent to your email will redirect to the digital certificate. The certificate is secured and encrypted to prevent counterfeiting.
          Upon visiting the link, every certificate gets verified and checked for validity. Now, you can share it on your resume, Linkedin or any other social media
          to showcase your success.
        </Typography>
        <Box sx={{textAlign:"center", mt:10}}>
          <Typography variant={"h4"} color={"secondary"}  gutterBottom>Thank you for using the service.</Typography>
          <Button href={"https://spoo.me/padonate"} variant={"contained"} color={"primary"}  sx={{textAlign:"center"}}>Support us</Button>
        </Box>
      </Container>
  );
};

export default How;
