import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { HashLink as Link } from "react-router-hash-link";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import {Verified} from "@mui/icons-material";
import {useTheme} from "@mui/material";


function Copyright() {
  return (
    <Typography variant="body2" color="primary.light">
      {'Copyright © '}
      <Link to="https://profileaward.com/">ProfileAward&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
    const theme = useTheme();

    return (
      <Box sx={{backgroundColor:theme.palette.primary.dark, borderTop: '8px solid '+theme.palette.secondary.main }}>
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 4 },
        pt: { xs: 8, sm: 8 },
          pb: { xs: 8, sm: 4 },

          textAlign: { sm: 'center', md: 'left' },
          borderTop: '1px solid',
          borderColor: 'divider',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ mb:3, display:'flex' }}>
                <Verified sx={{ display:'flex', mr: 1, color:'white' }}  />
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    to="/"
                    color={"white"}
                    sx={{
                        mr: 2,
                        display:  'flex',
                        fontWeight: 700,
                        textDecoration: 'none',
                    }}
                >
                    profile <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    to="/"
                    color={"primary.light"}
                    sx={{
                        mr: 2,
                        display:  'flex',
                        // fontWeight: 700,
                        textDecoration: 'none',
                    }}
                >award
                </Typography>
                </Typography>
            </Box>
            <Typography variant="body1" gutterBottom color={'primary.light'}>
              Contact us
            </Typography>
            <Typography variant="body1" color="white" mb={2}>
              contact@profileaward.com
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
            color:'white'
          }}
        >
          <Typography variant="body2" fontWeight={600} color={"primary.light"}>
            Product
          </Typography>
          <Link color="text.secondary" to="/#scan">
            Scan my profile
          </Link>
          <Link color="text.secondary" to="/how">
            How it works?
          </Link>
          <Link color="text.secondary" to="https://spoo.me/padonate">
            Support us
          </Link>
          <Link color="text.secondary" to="/#faq">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
              color:'white'
          }}
        >
          <Typography variant="body2" fontWeight={600} color={"primary.light"}>
            Platforms
          </Typography>
          <Link color="text.secondary" to="#">
            Upwork
          </Link>
          <Link color="text.secondary" to="#">
            X (Twitter)
          </Link>
        </Box>
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: { xs: 'none', sm: 'flex' },*/}
        {/*    flexDirection: 'column',*/}
        {/*    gap: 1,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography variant="body2" fontWeight={600}>*/}
        {/*    Legal*/}
        {/*  </Typography>*/}
        {/*  <Link color="text.secondary" href="#">*/}
        {/*    Terms*/}
        {/*  </Link>*/}
        {/*  <Link color="text.secondary" href="#">*/}
        {/*    Privacy*/}
        {/*  </Link>*/}
        {/*  <Link color="text.secondary" href="#">*/}
        {/*    Contact*/}
        {/*  </Link>*/}
        {/*</Box>*/}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 2 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          {/*<Link color="text.secondary" href="#">*/}
          {/*  Privacy Policy*/}
          {/*</Link>*/}
          {/*<Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>*/}
          {/*  &nbsp;•&nbsp;*/}
          {/*</Typography>*/}
          {/*<Link color="text.secondary" href="#">*/}
          {/*  Terms of Service*/}
          {/*</Link>*/}
          <Copyright />
        </div>
        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  justifyContent="left"*/}
        {/*  spacing={1}*/}
        {/*  useFlexGap*/}
        {/*  sx={{*/}
        {/*    color: 'text.secondary',*/}
        {/*      display:'none'*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <IconButton*/}
        {/*    color="inherit"*/}
        {/*    href="https://github.com/mui"*/}
        {/*    aria-label="GitHub"*/}
        {/*    sx={{ alignSelf: 'center' }}*/}
        {/*  >*/}
        {/*    <FacebookIcon />*/}
        {/*  </IconButton>*/}
        {/*  <IconButton*/}
        {/*    color="inherit"*/}
        {/*    href="https://x.com/MaterialUI"*/}
        {/*    aria-label="X"*/}
        {/*    sx={{ alignSelf: 'center' }}*/}
        {/*  >*/}
        {/*    <TwitterIcon />*/}
        {/*  </IconButton>*/}
        {/*  <IconButton*/}
        {/*    color="inherit"*/}
        {/*    href="https://www.linkedin.com/company/mui/"*/}
        {/*    aria-label="LinkedIn"*/}
        {/*    sx={{ alignSelf: 'center' }}*/}
        {/*  >*/}
        {/*    <LinkedInIcon />*/}
        {/*  </IconButton>*/}
        {/*</Stack>*/}
      </Box>
    </Container>
      </Box>
  );
}